const config = state => state.app.config
const palette = state => state.app.config.palette
const isLoading = state => state.app.isLoading
const currentEmail = state => state.email
const roles = state => state.roles

const currentUser = state =>
  state.email != null
    ? state.email.substring(0, state.email.indexOf('@'))
    : null

export { config, palette, isLoading, currentEmail, currentUser, roles }
