export const navigationRoutes = {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [{
    name: 'dashboard',
    displayName: 'menu.dashboard',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
    },
  },

  {
    name: 'quitados',
    displayName: 'menu.quitados',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-extras',
    },
  },
  {
    name: 'adimplentes',
    displayName: 'menu.adimplentes',
    meta: {
      iconClass: 'fa fa-thumbs-o-up',
    },
  },
  {
    name: 'emAtraso',
    displayName: 'menu.emAtraso',
    meta: {
      iconClass: 'fa fa-thumbs-o-down',
    },
  },
  {
    name: 'telefonesInadimplentes',
    displayName: 'menu.telefonesInadimplentes',
    meta: {
      iconClass: 'fa fa-phone',
    },
  },
  {
    name: 'Negativação',
    displayName: 'menu.negativacao',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-files',
    },
    children: [{
      name: 'negativados',
      displayName: 'menu.negativados',
    },
    {
      name: 'prontosNegativar',
      displayName: 'menu.prontosNegativar',
    },
    {
      name: 'prontosNegativarCNPJ',
      displayName: 'menu.prontosNegativarCNPJ',
    },
    {
      name: 'prontosNegativarAvalistas',
      displayName: 'menu.prontosNegativarAvalistas',
    },
    {
      name: 'removerNegativacao',
      displayName: 'menu.removerNegativacao',
    },
    {
      name: 'poderaoSerRemovidos',
      displayName: 'menu.poderaoSerRemovidos',
    },
    {
      name: 'seraoNegativados',
      displayName: 'menu.seraoNegativados',
    },
    ],
  },
  {
    name: 'ajuizamento',
    displayName: 'menu.ajuizamento',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-tables',
    },
    children: [{
      name: 'prontosAjuizar',
      displayName: 'menu.prontosAjuizar',
    },
    {
      name: 'ajuizados',
      displayName: 'menu.ajuizados',
    },
    {
      name: 'seraoAjuizados',
      displayName: 'menu.seraoAjuizados',
    },
    ],
  },
  {
    name: 'statusMensagensInvestidores',
    displayName: 'menu.mensagensInvestidores',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-comments',
    },
  },
  ],
}
