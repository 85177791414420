import Vue from 'vue'
import Vuex from 'vuex'
import VuexI18n from 'vuex-i18n' // load vuex i18n module
import app from './modules/app'

import * as getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: true, // process.env.NODE_ENV !== 'production',
  getters,
  modules: {
    app,
  },
  state: {
    notifications: [],
    showNotifications: false,
    isLoggedIn: !!localStorage.getItem('token'),
    expDate: localStorage.getItem('expDate'),
    roles: localStorage.getItem('roles'),
    email: localStorage.getItem('email'),
    isLoading: localStorage.getItem('isLoading'),
  },
  mutations: {
    setToken (state, token) {
      state.isLoggedIn = !!token
      localStorage.setItem('token', `Bearer ${token}`)
    },
    setRefreshToken (state, refreshToken) {
      localStorage.setItem('refreshToken', refreshToken)
    },
    setIsloading (value) {
      console.log('setIsloading mutation', value)
      localStorage.setItem('isLoading', value)
    },
    setEmail (state, email) {
      console.log('setando email', email)
      localStorage.setItem('email', email)
    },
    setExpDate (state, expDate) {
      state.expDate = expDate
      localStorage.setItem('expDate', expDate)
    },
    setRoles: (state, roles) => {
      state.roles = roles.join(',')
      localStorage.setItem('roles', roles)
    },
    setLogout (state) {
      state.isLoggedIn = null
      state.expDate = null
      state.roles = []
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('expDate')
      localStorage.removeItem('roles')
      localStorage.removeItem('email')
    },
    setNotification: (state, notification) => {
      state.notifications.push(notification)
      state.showNotifications = true
    },
    clearNotification: state => {
      state.notifications.shift()
      state.showNotifications = false
    },
    showNotifications: state => {
      state.showNotifications = true
    },
  },
  actions: {
    setLogin ({ commit }, data) {
      commit('setToken', data.bearerToken)
      commit('setRefreshToken', data.refreshToken)
      commit('setRoles', data.roles)
      commit('setExpDate', data.expiration)
      commit('setEmail', data.email)
    },
    setLogout ({ commit }) {
      commit('setLogout')
    },
    setIsloading ({ commit }, data) {
      console.log('setIsloading action', data)
      commit('setIsloading', data)
    },
    notification ({ commit }, { type, message }) {
      const notification = { type, message }
      commit('setNotification', notification)
    },
    clearNotification ({ commit, state }) {
      commit('clearNotification')
      setTimeout(() => {
        if (state.notifications.length) {
          commit('showNotifications')
        }
      }, 500)
    },
  },
})

Vue.use(VuexI18n.plugin, store)

export default store
