import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '../components/auth/AuthLayout'
import AppLayout from '../components/admin/AppLayout'
import Store from '@/store/'

Vue.use(Router)

const needAuth = next => {
  if (Store.state.isLoggedIn) {
    next()
  } else {
    next({ path: '/auth', replace: true })
  }
}

const EmptyParentComponent = {
  template: '<router-view></router-view>',
}

export default new Router({
  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes: [{
    path: '*',
    redirect: { name: 'dashboard' },
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [{
      name: 'login',
      path: 'login',
      component: () =>
                        import('../components/auth/login/Login.vue'),
    },
    {
      path: '',
      redirect: { name: 'login' },
    },
    ],
  },
  {
    path: '/404',
    component: EmptyParentComponent,
    children: [{
      name: 'not-found-advanced',
      path: 'not-found-advanced',
      component: () =>
                        import('../components/pages/404-pages/VaPageNotFoundSearch.vue'),
    },
    {
      name: 'not-found-simple',
      path: 'not-found-simple',
      component: () =>
                        import('../components/pages/404-pages/VaPageNotFoundSimple.vue'),
    },
    {
      name: 'not-found-custom',
      path: 'not-found-custom',
      component: () =>
                        import('../components/pages/404-pages/VaPageNotFoundCustom.vue'),
    },
    {
      name: 'not-found-large-text',
      path: '/pages/not-found-large-text',
      component: () =>
                        import('../components/pages/404-pages/VaPageNotFoundLargeText.vue'),
    },
    ],
  },
  {
    name: 'Admin',
    path: '/admin',
    component: AppLayout,
    children: [{
      name: 'dashboard',
      path: 'dashboard',
      component: () =>
                        import('../components/dashboard/Dashboard.vue'),
      default: true,
      beforeEnter: (to, from, next) => needAuth(next),
    },
    {
      name: 'prontosNegativar',
      path: 'prontos-negativar',
      component: () =>
                        import('../components/negativacao/ProntosNegativar.vue'),
      beforeEnter: (to, from, next) => needAuth(next),
    },
    {
      name: 'prontosNegativarCNPJ',
      path: 'prontos-negativar-cnpj',
      component: () =>
                        import('../components/negativacao/ProntosNegativarCNPJ.vue'),
      beforeEnter: (to, from, next) => needAuth(next),
    },
    {
      name: 'prontosNegativarAvalistas',
      path: 'prontos-negativar-avalistas',
      component: () =>
                        import('../components/negativacao/ProntosNegativarAvalistas.vue'),
      beforeEnter: (to, from, next) => needAuth(next),
    },
    {
      name: 'quitados',
      path: 'contratos-quitados',
      component: () =>
                        import('../components/negativacao/Quitados.vue'),
      beforeEnter: (to, from, next) => needAuth(next),
    },
    {
      name: 'adimplentes',
      path: 'contratos-adimplentes',
      component: () =>
                        import('../components/negativacao/Adimplentes.vue'),
      beforeEnter: (to, from, next) => needAuth(next),
    },
    {
      name: 'telefonesInadimplentes',
      path: 'contato-com-inadimplentes',
      component: () =>
                        import('../components/negativacao/TelefonesInadimplentes.vue'),
      beforeEnter: (to, from, next) => needAuth(next),
    },
    {
      name: 'poderaoSerRemovidos',
      path: 'poderao-ser-removidos-caso-paguem-acordo',
      component: () =>
                        import('../components/negativacao/PoderaoSerRemovidos.vue'),
      beforeEnter: (to, from, next) => needAuth(next),
    },
    {
      name: 'negativados',
      path: 'negativados',
      component: () =>
                        import('../components/negativacao/Negativados.vue'),
      beforeEnter: (to, from, next) => needAuth(next),
    },
    {
      name: 'statusMensagensInvestidores',
      path: 'status-mensagens-investidores',
      component: () =>
                        import('../components/negativacao/StatusMensagensInvestidores.vue'),
      beforeEnter: (to, from, next) => needAuth(next),
    },
    {
      name: 'removerNegativacao',
      path: 'remover-negativacao',
      component: () =>
                        import('../components/negativacao/RemoverNegativacao.vue'),
      beforeEnter: (to, from, next) => needAuth(next),
    },
    {
      name: 'seraoNegativados',
      path: 'proximos-negativar',
      component: () =>
                        import('../components/negativacao/SeraoNegativados.vue'),
      beforeEnter: (to, from, next) => needAuth(next),
    },
    {
      name: 'ajuizados',
      path: 'ajuizados',
      component: () =>
                        import('../components/negativacao/Ajuizados.vue'),
      beforeEnter: (to, from, next) => needAuth(next),
    },
    {
      name: 'prontosAjuizar',
      path: 'prontos-ajuizar',
      component: () =>
                        import('../components/negativacao/ProntosAjuizar.vue'),
      beforeEnter: (to, from, next) => needAuth(next),
    },
    {
      name: 'seraoAjuizados',
      path: 'proximos-ajuizar',
      component: () =>
                        import('../components/negativacao/SeraoAjuizados.vue'),
      beforeEnter: (to, from, next) => needAuth(next),
    },
    {
      name: 'emAtraso',
      path: 'operacoes-em-atraso',
      component: () =>
                        import('../components/negativacao/EmAtraso.vue'),
      beforeEnter: (to, from, next) => needAuth(next),
    },
    ],
  },
  ],
})
